<template>
  <div class="has-nav-bar view-workticket-process">
    <WorkticketHeader title="过程记录"></WorkticketHeader>
    <div class="steps-box">
      <van-steps direction="vertical" :active="active" active-color="#1676FF">
        <van-step v-for="(item, index) in list" :key="index">
          <template #inactive-icon>
            <i
              :class="item.current === 1 ? 'active-icon' : 'inactive-icon'"
            ></i>
          </template>
          <template #active-icon>
            <i class="active-icon"></i>
          </template>

          <div class="process-item">
            <div class="title-line">
              <span class="right">{{ item.operationNodeName }}</span>
            </div>
            <div class="desc" :class="{ 'initial-height': item.isShowAllDesc }">
              {{ item.remark }}
              <span
                class="ellipsis-box show-all-btn"
                @click="showDescClick(true, index)"
                >展开</span
              >
              <span class="after"></span>
            </div>
            <p v-show="item.isShowAllDesc" class="packup-box">
              <span class="show-all-btn" @click="showDescClick(false, index)"
                >收起</span
              >
            </p>
            <p class="time">
              <span class="icon-item"
                ><i
                  :style="
                    `background-image: url(${require('@/assets/images/workticket-item-5.png')})`
                  "
                ></i
                >{{ item.cuserName }}</span
              >
              <span v-if="item.ctime" class="icon-item"
                ><i
                  :style="
                    `background-image: url(${require('@/assets/images/workticket-item-3.png')})`
                  "
                ></i
                >{{ item.ctime }}</span
              >
            </p>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import WorkticketHeader from "@/components/header/WorkticketHeader";
import { getWorkBeforeProcess } from "@/api/workticket";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
export default {
  name: "WorkticketProcess",
  components: {
    WorkticketHeader
  },
  data() {
    return {
      id: "",
      active: 99999,
      list: []
    };
  },
  created: function() {},
  mounted: function() {
    let { id } = this.$route.params;
    this.id = id;
    getWorkBeforeProcess(id).then(res => {
      this.list = (Array.isArray(res) ? res : []).map(item => {
        item.isShowAllDesc = false;
        if (item.current === 1) {
          item.cuserName = item.operatorList ? item.operatorList.join(",") : "";
        }
        return item;
      });
    });
  },
  methods: {
    showDescClick(show, idx) {
      this.list[idx].isShowAllDesc = show;
    },
    showSignature(fileId) {
      esign.showImage(fileUrl + fileId);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.inactive-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #c3c6d0;
  border-radius: 50%;
}
.van-step--finish .inactive-icon {
  background-color: $--color-primary;
}
.active-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #ffffff;
  border: 2px solid $--color-primary;
  border-radius: 50%;
}
</style>

<style lang="scss" scoped>
.view-workticket-process {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .van-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    ::v-deep {
      .van-tabs__content {
        flex: 1 0 0;
        overflow-y: scroll;
      }
    }
  }
}
.inline-block {
  display: inline-block;
}

.steps-box {
  padding: 10px;
  box-sizing: border-box;
}
.process-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  .process-box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .process-node-diamond {
    display: flex;
    width: 235px;
    flex-direction: row;
    justify-content: space-between;
  }
  .process-arrow-polyline {
    display: flex;
    justify-content: space-between;
    width: 164px;
  }
  .process-arrow-double {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .arrow-start,
    .arrow-end {
      position: relative;
      display: block;
      width: 38px;
      height: 10px;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #c7c9d0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
    .arrow-start {
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right-width: 6px;
        border-right-color: #c7c9d0;
        left: -5px;
        top: 0;
      }
    }
    .arrow-end {
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-left-color: #c7c9d0;
        border-left-width: 6px;
        right: -5px;
        top: 0;
      }
    }
  }
}

.process-item {
  font-size: 12px;
  color: $--color-gray-item-text;

  .title-line {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: $--color-black-title;

    .title {
      flex-grow: 1;
    }
  }

  .initial-height {
    max-height: initial !important;
  }

  .qianming {
    font-size: 16px;
    color: #1676ff;
    margin: 0 0 0 8px;
  }

  .modify-person-box {
    font-size: 12px;
    font-weight: 400;
    color: #8c8f97;
    line-height: 18px;
    margin: 0 0 8px;
    // span {
    //   vertical-align: middle;
    // }
    .name-box {
      display: inline-block;
      margin: 0 8px 0 0;
      // vertical-align: initial;
    }
    .name-icon-box {
      display: inline-block;
      margin: 0 12px 0 0;
      i {
        margin: 0 0 0 4px;
        // vertical-align: middle;
      }
    }
  }

  .desc {
    max-height: 54px;
    font-size: 12px;
    font-weight: 400;
    color: #8c8f97;
    line-height: 18px;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    word-break: break-all;
    .ellipsis-box {
      text-align: right;
      width: 54px;
      position: absolute;
      right: 0px;
      bottom: 0;
      background: #fff;
      background: -webkit-gradient(
        linear,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: -moz-linear-gradient(
        to right,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: -o-linear-gradient(
        to right,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: -ms-linear-gradient(
        to right,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: linear-gradient(
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
    }
    .after {
      height: 18px;
      width: 54px;
      position: absolute;
      right: 0px;
      background-color: white;
    }
  }

  .show-all-btn {
    color: #1676ff;
  }
  .packup-box {
    text-align: right;
    margin-top: -8px;
  }

  .icon-item {
    display: inline-block;
    margin-right: 20px;

    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      vertical-align: top;
      margin-right: 5px;
      background-size: cover;
    }
  }
}
</style>
